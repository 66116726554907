@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.root {
  @include mixins.card();
}

.hasParentCard {
  display: grid;
  gap: 1rem;
}
