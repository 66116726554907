@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.root {
    @include mixins.card-action-overlay();
}

.loading {
    composes: root;
    @include mixins.shimmer($position: absolute);
}
